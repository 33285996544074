module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"none","disableBgImage":true,"linkImagesToOriginal":false,"maxWidth":816,"quality":72,"showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ricky de Laveaga","short_name":"rdela","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","include_favicon":false,"legacy":false,"icons":[{"src":"/icons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/icons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/icons/rdela-192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/icons/rdela-512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
